.inner-con {
  width: 18.5%;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: -60px;
  left: 36%;
}

.inner-con h3 {
  color: #FFF;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.inner-con h3 img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.inner-con p {
  color: #FFF;
  font-size: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: 168.624%;
  /* 42.156px */
  font-variant: small-caps;
}


.inner-con span {
  color: #F6BEFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  position: relative;
}

.inner-con span img {
  content: '';
  position: absolute;
  /* background-image: url(./Assets/Images/vinith-svg.png); */
  width: fit-content;
  background-size: cover;
  background-position: calc();
  background-repeat: no-repeat;
  height: 100%;
  top: 0px;
}

.main-img {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;

}

.main-img img {
  width: 100% !important;
  height: 70vh !important;
  object-fit: contain;
  transition: 'width 1s'
}

.coffee-anim img {
  width: 30px !important;
  height: 30px !important;
  position: relative !important;
}

.coffee {
  width: 10px;
  height: 10px;
  position: absolute;
  object-fit: contain;
  background-color: transparent;
  background-blend-mode:screen;
}

.left-smoke {
  width: 2px !important;
  height: 1px !important;
  position: absolute;
  top: -200px;
  left: 0;
}

.right-smoke {
  width: 4px !important;
  height: 10px !important;
  display: flex;
  align-items: self-start;
  justify-content: flex-end;
}